import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

interface GamePointProps {
  score: number;
}
const GamePoint: React.FC<GamePointProps> = ({ score }) => {
  const [state, setState] = React.useState(score);

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        height: 120,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        POINT
      </Typography>
      <Stack
        className="score-number "
        sx={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48px",
          height: "100%",
        }}
        onClick={() => setState(state + 1)}
      >
        {state}
      </Stack>
    </Paper>
  );
};

export default GamePoint;
