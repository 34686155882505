import React from "react";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

interface MatchTimeProps {
  player1: string;
  player2: string;
}
const MatchTime: React.FC<MatchTimeProps> = ({ player1, player2 }) => {
  const [timeOfDay, setTimeOfDay] = React.useState(moment());
  const [matchTime, setMatchTime] = React.useState(moment().startOf("day"));

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setTimeOfDay(moment());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  React.useEffect(() => {
    const timerId = setInterval(() => {
      setMatchTime((prevTime) => prevTime.clone().add(1, "minute"));
    }, 60000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  return (
    <Paper elevation={2}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", gap: 2, p: 1 }}
      >
        <Stack
          direction="row"
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{player1}</Typography>
          <Stack >
            <Typography>Time of Day</Typography>
            <Typography variant="h6">{timeOfDay.format("HH:mm")}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack >
            <Typography>Match Time</Typography>
            <Typography variant="h6">{matchTime.format("HH:mm")}</Typography>
          </Stack>
          <Typography sx={{ alignContent: "flex-end" }}>{player2}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MatchTime;
