import React from "react";

import SetScore from "./SetScore";
import MatchTime from "./MatchTime";
import PointReason from "./PointReason";
import Stack from "@mui/material/Stack";
import GamePoint from "./GamePoint";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

interface ScoreboardProps {}

const Scoreboard: React.FC<ScoreboardProps> = () => {
  const player1 = "Player Name 1";
  const player2 = "Player Name 2";
  return (
    <>
      <Paper elevation={2}>
        <Stack spacing={1} sx={{ height: "100%", flex: 1 }}>
          <SetScore name={player1} />
          <SetScore name={player2} />
        </Stack>
      </Paper>
      <MatchTime player1={player1} player2={player2} />
      <Paper elevation={2}>
        <Stack direction="row" spacing={1}>
          <Stack sx={{ flex: 2 }}>
            <PointReason />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ flex: 8, alignItems: "end" }}
          >
            <Stack sx={{ flex: 1 }}>
              <GamePoint score={0} />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <GamePoint score={0} />
            </Stack>
          </Stack>
          <Stack sx={{ flex: 2 }}>
            <PointReason />
          </Stack>
        </Stack>
      </Paper>
      <Paper elevation={3} sx={{ py: 1 }}>
        <Stack direction="row">
          <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
            Undo
          </Button>
          <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
            Refresh
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "40px", flex: 1, lineHeight: 1 }}
          >
            Change Court
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "40px", flex: 1, lineHeight: 1 }}
          >
            Change Serve
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "40px", flex: 1, lineHeight: 1 }}
          >
            Change Server
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "40px", flex: 1, lineHeight: 1 }}
          >
            Player Statistics
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "40px", flex: 1, lineHeight: 1 }}
          >
            Play History
          </Button>
          <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
            Info
          </Button>
          <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
            Exit
          </Button>
        </Stack>
      </Paper>
    </>
  );
};

export default Scoreboard;
