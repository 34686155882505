import Container from "@mui/material/Container";
import { StyledEngineProvider } from "@mui/material/styles";
import Scoreboard from "./components/Scoreboard";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Container>
        <Scoreboard />
      </Container>
    </StyledEngineProvider>
  );
}

export default App;
