import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const PointReason = () => {
  return (
    <ButtonGroup orientation="vertical">
      <Button sx={{ height: "40px" }}>ACE</Button>
      <Button sx={{ height: "40px" }}>WINNER</Button>
      <Button sx={{ height: "40px" }}>FAULT</Button>
      <Button sx={{ height: "40px", lineHeight: 1 }}>UNFORCE ERROR</Button>
    </ButtonGroup>
  );
};

export default PointReason;
