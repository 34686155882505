import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

interface SetScorePros {
  name: string;
}

const SetScore: React.FC<SetScorePros> = ({ name }) => {
  return (
    <Stack direction="row">
      <Stack direction="row" sx={{ flex: 1, justifyContent: "center" }}>
        <Typography variant="h6">{name}</Typography>
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Stack direction="row" spacing={8}>
          <Typography variant="h4" className="score-number ">
            1
          </Typography>
          <Typography variant="h4" className="score-number ">
            1
          </Typography>
          <Typography variant="h4" className="score-number ">
            1
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SetScore;
